import * as React from 'react'

// eslint-disable-next-line
export interface EmptyLayoutProps {}

export const EmptyLayout: React.FC<
  React.PropsWithChildren<EmptyLayoutProps>
> = ({ children }) => {
  return <div className="workspace workspace--empty">{children}</div>
}
