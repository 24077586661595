import {
  activationBusinessPathObject,
  activationCardPathObject,
  activationReviewPathObject,
} from '@lib/paths'
import { DuffelAPI } from '@lib/types'

export const activationStepsMap = {
  business: {
    title: 'Tell us about your business',
    isAvailable: (_organisation: DuffelAPI.Types.Organisation) => true,
    paths: activationBusinessPathObject,
  },
  card: {
    title: 'Set up your payments',
    isAvailable: (organisation: DuffelAPI.Types.Organisation) =>
      organisation.legalEntity,
    paths: activationCardPathObject,
  },
  review: {
    title: 'Activate your team',
    isAvailable: (organisation: DuffelAPI.Types.Organisation) =>
      organisation.legalEntity && organisation.stripePaymentMethodId,
    paths: activationReviewPathObject,
  },
}

export type ActivationStep = keyof typeof activationStepsMap

export * from './get-stripe-promise'
