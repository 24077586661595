import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Anchor } from '@components/Anchor'
import { DuffelAPI } from '@lib/types'
import { activationStepsMap } from '@modules/dashboard-activation/lib'

interface ActivationStepsSelectProps {
  organisation: DuffelAPI.Types.Organisation
  className?: string
}

export const ActivationStepsSelect: React.FC<ActivationStepsSelectProps> = ({
  organisation,
  className,
}) => {
  const { pathname } = useRouter()
  const step = pathname.split('/').pop()

  return (
    <div
      className={classNames('activation-steps', className)}
      data-selector="fs-show"
      data-testid="activation-sidebar"
    >
      <ul className="steps">
        {Object.entries(activationStepsMap).map(([key, value]) => {
          const isAvailable = value.isAvailable(organisation)
          const listClassNames = classNames('steps__item', {
            'steps__item--selected': step === key,
            'steps__item--available': isAvailable,
          })

          return (
            <li key={key} className={listClassNames}>
              {isAvailable ? (
                <Link
                  {...activationStepsMap[key].paths(organisation.slug)}
                  passHref
                  legacyBehavior
                >
                  <Anchor className="steps__item__anchor">{value.title}</Anchor>
                </Link>
              ) : (
                <div className="steps__item__label">{value.title}</div>
              )}
            </li>
          )
        })}
      </ul>

      <style jsx>{`
        .activation-steps {
          background-color: var(--white);
          border-radius: var(--border-radius-4);
          line-height: 1.5;
          font-size: var(--FONT-SIZES-C1);
          padding: var(--space-16);
          display: flex;
          height: fit-content;
        }

        .activation-steps > div {
          margin-right: 8px;
        }

        .steps {
          position: relative;
          margin: 0;
          padding: 0;
          list-style: none;
        }

        .steps::before {
          position: absolute;
          display: block;
          content: '';
          top: 10px;
          left: 11px;
          bottom: 10px;
          width: 2px;
          background-color: var(--purple-300);
        }

        .steps__item {
          position: relative;
          padding-left: var(--space-32);
          margin-bottom: var(--space-16);
        }

        .steps__item:last-child {
          margin-bottom: 0;
        }

        .steps__item::before,
        .steps__item::after {
          position: absolute;
          display: block;
          content: '';
          border-radius: 9999px;
        }

        .steps__item::before {
          border: 8px solid var(--white);
          background-color: var(--purple-300);
          width: 8px;
          height: 8px;
          top: 0px;
          left: 0px;
        }

        .steps__item::after {
          display: none;
          border: 2px solid var(--purple-500);
          width: 12px;
          height: 12px;
          top: 4px;
          left: 4px;
        }

        .steps__item--selected::after {
          display: block;
        }

        .steps__item--available::before {
          background-color: var(--purple-500);
        }

        .steps__item--available :global(.steps__item__anchor) {
          color: var(--purple-600);
        }

        .steps__item--selected :global(.steps__item__anchor) {
          font-weight: bold;
        }

        .steps__item__label {
          color: var(--grey-600);
        }
      `}</style>
    </div>
  )
}
