import * as React from 'react'
import { ErrorView, ErrorViewProps } from '@components/ErrorView'
import { DefaultLayoutHeader } from '../DefaultLayoutHeader/DefaultLayoutHeader'

export interface ErrorLayoutProps extends ErrorViewProps {
  hideNavigation?: boolean
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  hideNavigation = false,
  title,
  subtitle,
  errorCode,
}) => {
  return (
    <div className="workspace">
      {!hideNavigation && <DefaultLayoutHeader testModeNotice="hide" />}
      <ErrorView title={title} subtitle={subtitle} errorCode={errorCode} />

      <style jsx>{`
        .workspace {
          position: relative;
          min-height: 100vh;
          min-width: 1062px;
          display: flex;
          flex-direction: column;
        }

        .workspace :global(.error-view) {
          min-height: unset;
          flex: 1;
        }
      `}</style>
    </div>
  )
}
