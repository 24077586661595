import classNames from 'classnames'
import Link from 'next/link'
import * as React from 'react'
import { ErrorView } from '@components/ErrorView'
import { Icon } from '@components/Icon'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { orgIndexPathObject } from '@lib/paths'
import { APIResponseError, DuffelAPI } from '@lib/types'
import { ActivationStepsSelect } from './ActivationStepsSelect'

interface ActivationLayoutProps {
  error?: APIResponseError
  organisation?: DuffelAPI.Types.Organisation
}

export const ActivationLayout: React.FC<
  React.PropsWithChildren<ActivationLayoutProps>
> = ({ children, organisation, error }) => {
  if (error) {
    return (
      <ErrorView
        title="Something went wrong"
        subtitle={UNKNOWN_ERROR_MESSAGE}
      />
    )
  }

  return (
    <div className="workspace">
      <div className="workspace__header" data-selector="fs-show">
        <Link
          {...orgIndexPathObject(organisation?.slug, organisation?.verified)}
          passHref
          legacyBehavior
        >
          <a>
            <Icon name="close" />
          </a>
        </Link>
        Activate your team
      </div>
      <div
        className={classNames('workspace__main', {
          'workspace__main--with-sidebar': organisation !== undefined,
        })}
      >
        {organisation && (
          <ActivationStepsSelect
            organisation={organisation}
            className="workspace__main__sidebar"
          />
        )}
        <div className="workspace__main__content">{children}</div>
      </div>
      <style jsx>{`
        :global(body) {
          background-color: var(--white) !important;
        }

        .workspace {
          width: 100vw;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .workspace__header {
          position: sticky;
          top: 0;
          z-index: 3;

          display: flex;
          align-items: center;
          padding: 16px 32px;

          background-color: var(--white);
          color: var(--grey-900);
          font-size: var(--FONT-SIZES-H3);
          line-height: 1.5;
          border-bottom: 2px solid var(--grey-200);
        }

        :global(.workspace__header .ff-icon) {
          margin-right: 16px;
          color: var(--grey-900);
        }

        .workspace__main {
          display: flex;
          padding: 48px;
          justify-content: center;
          align-items: center;
          flex-grow: 1;

          font-size: var(--FONT-SIZES-C1);
          line-height: 1.5;
        }

        .workspace__main--with-sidebar > :global(.workspace__main__sidebar) {
          position: sticky;
          top: 112px;
          background-color: var(--white);
          border: 1px solid var(--grey-200);
        }

        .workspace__main > .workspace__main__content {
          width: 520px !important;
          text-align: center;
          padding-bottom: 80px;
          height: 100%;
        }

        .workspace__main--with-sidebar {
          align-items: flex-start;
        }

        @media (max-width: 800px) {
          .workspace__main {
            flex-direction: column;
          }

          .workspace__main--with-sidebar > :global(.workspace__main__sidebar) {
            position: inherit;
            width: 100%;
          }
        }

        .workspace__main--with-sidebar > .workspace__main__content {
          width: 480px;
          text-align: left;
        }

        .workspace__main--with-sidebar {
          align-items: flex-start;
        }

        @media (max-width: 800px) {
          .workspace__main {
            flex-direction: column;
          }

          .workspace__main--with-sidebar > :global(.workspace__main__sidebar) {
            position: inherit;
            width: 100%;
          }
        }

        .workspace__main--with-sidebar > .workspace__main__content {
          width: 720px;
          text-align: left;
        }

        :global(.activation-steps) {
          margin-right: 48px;
          margin-bottom: 48px;
          min-width: 280px;
        }
      `}</style>
    </div>
  )
}

export default ActivationLayout
