import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { ErrorBoundary } from '@components/ErrorBoundary'
import { Icon } from '@components/Icon'
import {
  doesPathNameMatch,
  organisationBalancePreferencesIndexPathObject,
  organisationBusinessIndexPathObject,
  organisationContactEmailsIndexPathObject,
  organisationPreferencesIndexPathObject,
  organisationSecurityIndexPathObject,
  organisationUsageAndBillingIndexPathObject,
  organisationWalletPreferencesIndexPathObject,
  orgIndexPathObject,
  PathKey,
  teamIndexPathObject,
} from '@lib/paths'
import { AuthorisationGuard } from '@lib/security/AuthorisationGuard'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import styles from './SettingsLayout.module.css'

interface SettingsLayoutProps {
  organisation?: DuffelAPI.Types.Organisation
}

export const SettingsLayout: React.FC<
  React.PropsWithChildren<SettingsLayoutProps>
> = ({ children, organisation }) => {
  const pathname = useRouter().pathname
  const { permissions, user, currentOrganisation } = useWorkspace()
  const currentOrg = organisation || currentOrganisation

  const backPathObject = currentOrg
    ? orgIndexPathObject(currentOrg.slug, permissions?.liveMode)
    : { href: '/' }

  const currentOrgIsVerified =
    organisation?.verified || currentOrganisation?.isVerified

  if (!permissions || !user) return null

  const menuItemClassNames = (pathkey: PathKey) =>
    classNames(styles['settings-menu__item'], {
      [`${styles['settings-menu__item--active']}`]: doesPathNameMatch(
        pathname,
        pathkey
      ),
    })

  return (
    <div className={styles['settings-layout']}>
      <div className={styles['settings-layout__content']}>
        <div className={styles['settings-layout__nav']}>
          <div className={styles['settings-layout__nav__sticky']}>
            <div className={styles['settings-menu']}>
              <Link
                {...backPathObject}
                className={styles['settings-menu__item']}
              >
                <Icon name="arrow_left" size={16} />
                <span>Go back</span>
              </Link>
            </div>

            {currentOrg && (
              <AuthorisationGuard requiredRole="roles/user/admin">
                <div className={styles['settings-menu']}>
                  <div className={styles['settings-menu__label']}>
                    Team settings
                  </div>
                  <Link
                    {...organisationPreferencesIndexPathObject(currentOrg.slug)}
                    className={menuItemClassNames(
                      'organisationPreferencesIndex'
                    )}
                  >
                    <Icon name="settings" size={16} />
                    <span>Preferences</span>
                  </Link>

                  <Link
                    {...organisationContactEmailsIndexPathObject(
                      currentOrg.slug
                    )}
                    className={menuItemClassNames(
                      'organisationContactEmailsIndex'
                    )}
                  >
                    <Icon name="mail" size={16} />
                    <span>Contact emails</span>
                  </Link>

                  {permissions.liveMode && (
                    <Link
                      {...organisationUsageAndBillingIndexPathObject(
                        currentOrg.slug
                      )}
                      className={menuItemClassNames(
                        'organisationUsageAndBillingIndex'
                      )}
                    >
                      <Icon name="card" size={16} />
                      <span>Usage and billing</span>
                    </Link>
                  )}

                  <Link
                    {...organisationSecurityIndexPathObject(currentOrg.slug)}
                    className={menuItemClassNames('organisationSecurityIndex')}
                  >
                    <Icon name="lock" size={16} />
                    <span>Security</span>
                  </Link>

                  {currentOrg.verificationFlow === 'stripe_connect' ? (
                    <Link
                      {...organisationBalancePreferencesIndexPathObject(
                        currentOrg.slug
                      )}
                      className={menuItemClassNames(
                        'organisationBalancePreferencesIndex'
                      )}
                    >
                      <Icon name="payments" size={16} />
                      <span>Balance</span>
                    </Link>
                  ) : (
                    <Link
                      {...organisationWalletPreferencesIndexPathObject(
                        currentOrg.slug
                      )}
                      className={menuItemClassNames(
                        'organisationWalletPreferencesIndex'
                      )}
                    >
                      <Icon name="payments" size={16} />
                      <span>Balance</span>
                    </Link>
                  )}

                  {currentOrgIsVerified && (
                    <Link
                      {...organisationBusinessIndexPathObject(currentOrg.slug)}
                      className={menuItemClassNames(
                        'organisationBusinessIndex'
                      )}
                    >
                      <Icon name="business" size={16} />
                      <span>Business</span>
                    </Link>
                  )}

                  <Link
                    {...teamIndexPathObject(currentOrg.slug)}
                    className={menuItemClassNames('teamIndex')}
                  >
                    <Icon name="people" size={16} />
                    <span>Team members</span>
                  </Link>
                </div>
              </AuthorisationGuard>
            )}

            <div className={styles['settings-menu']}>
              <div className={styles['settings-menu__label']}>My account</div>
              <div>
                <Link
                  href="/user/settings"
                  as="/user/settings"
                  className={menuItemClassNames('settingsIndex')}
                >
                  <Icon name="person" size={16} />
                  <span>Preferences</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          id="settings-layout-main"
          className={styles['settings-layout__main']}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    </div>
  )
}
